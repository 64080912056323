export const settings = {
  apiURL: "https://library-app-backend-v2.herokuapp.com",
  siteName: "Diligent Libraries",
  siteUrl: "https://diligentlibraries.com/",
  imageServer: "https://book-loan-center.herokuapp.com",
  email: "info@diligentlibraries.com",
  phone1: "(626) 529-2381",
  phone2: "(626) 529-2382",
  address: "N McCadden Pl, Los Angeles, CA 90028, USA",
  description:
    "Library Fast provides loaning hundreds of books with only one click",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.7076267469124!2d-118.34126662095456!3d34.10262966851642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bf230de075d9%3A0xb944509d7be092a0!2sN%20McCadden%20Pl%2C%20Los%20Angeles%2C%20CA%2090028%2C%20USA!5e0!3m2!1sen!2str!4v1673266202973!5m2!1sen!2str",
};